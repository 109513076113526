import React from 'react';
import { navigate } from 'gatsby';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';

const withAuthorization = (condition) => (WrappedComponent) => {
  class AuthorizationHOC extends React.Component {
    _initFirebase = false;

    firebaseInit = () => {
      if (this.props.firebase && !this._initFirebase) {
        this._initFirebase = true;

        this.listener = this.props.firebase.onAuthUserListener(
          (authUser) => {
            if (!condition(authUser)) {
              navigate('/login');
            }
          },
          () => navigate('/login'),
        );
      }
    };

    componentDidMount() {
      this.firebaseInit();
    }

    componentDidUpdate() {
      this.firebaseInit();
    }

    componentWillUnmount() {
      this.listener && this.listener();
    }

    render() {
      return (
        <AuthUserContext.Consumer>
          {(authUser) => {
            return condition(authUser) ? (
              <WrappedComponent {...this.props} />
            ) : null;
          }}
        </AuthUserContext.Consumer>
      );
    }
  }

  return withFirebase(AuthorizationHOC);
};

export default withAuthorization;
