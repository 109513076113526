import React from 'react';
import { Algolia } from 'styled-icons/fa-brands/Algolia';
import { css } from '@emotion/core';

export const PoweredBy = () => (
  <span
    css={css`
      font-size: 0.6em;
      text-align: end;
      padding: 0;
    `}
  >
    Powered by{` `}
    <a href="https://algolia.com">
      <Algolia size="1em" /> Algolia
    </a>
  </span>
);
