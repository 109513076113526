import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import MDXRenderer from 'gatsby-plugin-mdx/mdx-renderer';
import styled from '@emotion/styled';
import { Layout } from '$components';

import GlobalCss from '../components/GlobalCss';
import NextPrevious from '../components/NextPrevious';
import '../components/styles.css';
import config from '../../config';
import FirebaseProvider from '../components/FirebaseProvider';

const forcedNavOrder = config.sidebar.forcedNavOrder;

const Main = styled.main`
  color: ${({ theme }) => theme.colors.font};
  max-width: 750px;

  a {
    color: inherit;
    text-decoration: underline;
  }
`;

export default class MDXRuntimeTest extends Component {
  render() {
    const { data } = this.props;
    const {
      allMdx,
      mdx,
      site: {
        siteMetadata: { docsLocation, title },
      },
    } = data;

    const navItems = allMdx.edges
      .map(({ node }) => node.fields.slug)
      .filter((slug) => slug !== '/')
      .sort()
      .reduce(
        (acc, cur) => {
          if (forcedNavOrder.find((url) => url === cur)) {
            return { ...acc, [cur]: [cur] };
          }

          const prefix = cur.split('/')[1];

          if (prefix && forcedNavOrder.find((url) => url === `/${prefix}`)) {
            return { ...acc, [`/${prefix}`]: [...acc[`/${prefix}`], cur] };
          } else {
            return { ...acc, items: [...acc.items, cur] };
          }
        },
        { items: [] },
      );

    const nav = forcedNavOrder
      .reduce((acc, cur) => {
        return acc.concat(navItems[cur]);
      }, [])
      .concat(navItems.items)
      .map((slug) => {
        if (slug) {
          const { node } = allMdx.edges.find(
            ({ node }) => node.fields.slug === slug,
          );

          return { title: node.fields.title, url: node.fields.slug };
        }
      });

    // meta tags
    const metaTitle = mdx.frontmatter.metaTitle;
    const metaDescription = mdx.frontmatter.metaDescription;
    let canonicalUrl = config.gatsby.siteUrl;
    canonicalUrl =
      config.gatsby.pathPrefix !== '/'
        ? canonicalUrl + config.gatsby.pathPrefix
        : canonicalUrl;
    canonicalUrl = canonicalUrl + mdx.fields.slug;

    return (
      <FirebaseProvider>
        <Layout {...this.props}>
          <GlobalCss>
            <Helmet>
              {metaTitle ? (
                <title>Eyemobile - Base de Conhecimento | {metaTitle}</title>
              ) : (
                <title>Eyemobile - Base de Conhecimento</title>
              )}
              <meta
                name="title"
                content={metaTitle || 'Eyemobile - Base de Conhecimento'}
              />
              {metaDescription ? (
                <meta name="description" content={metaDescription} />
              ) : null}
              {metaTitle ? (
                <meta property="og:title" content={metaTitle} />
              ) : null}
              {metaDescription ? (
                <meta property="og:description" content={metaDescription} />
              ) : null}
              {metaTitle ? (
                <meta property="twitter:title" content={metaTitle} />
              ) : null}
              {metaDescription ? (
                <meta
                  property="twitter:description"
                  content={metaDescription}
                />
              ) : null}
              <link rel="canonical" href={canonicalUrl} />
            </Helmet>
            <Main>
              <MDXRenderer fields={mdx.fields}>{mdx.body}</MDXRenderer>
            </Main>
            <div style={{ paddingTop: 36, paddingBottom: 50 }}>
              <NextPrevious mdx={mdx} nav={nav} />
            </div>
          </GlobalCss>
        </Layout>
      </FirebaseProvider>
    );
  }
}

export const pageQuery = graphql`
  query($id: String!) {
    site {
      siteMetadata {
        title
        docsLocation
      }
    }
    mdx(fields: { id: { eq: $id } }) {
      fields {
        id
        title
        slug
        visibility
      }
      body
      tableOfContents
      parent {
        ... on File {
          relativePath
        }
      }
      frontmatter {
        metaTitle
        metaDescription
      }
    }
    allMdx {
      edges {
        node {
          fields {
            slug
            title
          }
        }
      }
    }
  }
`;
