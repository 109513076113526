import React from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Search } from 'styled-icons/fa-solid/Search';

const SearchIcon = styled(Search)`
  width: 1em;
  pointer-events: none;
  margin-right: 10px;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  flex-shrink: 0;
  color: #c4c7d8;
`;

const focus = css`
  background: white;
  color: ${(props) => props.theme.darkBlue};
  cursor: text;
  width: 5em;
  & + ${SearchIcon} {
    color: ${(props) => props.theme.darkBlue};
    margin: 0.3em;
  }
`;
const collapse = css`
  width: 0;
  cursor: pointer;
  color: ${(props) => props.theme.lightBlue};
  & + ${SearchIcon} {
    color: white;
  }
`;
const expand = css`
  background: ${(props) => props.theme.veryLightGray};
  width: 100%;
  + ${SearchIcon} {
    margin: 0.3em;
  }
`;

const Input = styled.input`
  outline: none;
  border: none;
  font-size: 12px;
  background: white;
  transition: ${(props) => props.theme.shortTrans};
  border-radius: ${(props) => props.theme.smallBorderRadius};
  color: #737689;
  ${(props) => (props.collapse ? collapse : expand)};
  &::placeholder {
    color: inherit;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  position: relative;
  background-color: transparent;

  &:focus {
    outline: none;
    border: none;
  }
`;

export default connectSearchBox(({ refine, ...rest }) => {
  const searchCustom = '';
  const preventSubmit = (e) => {
    e.preventDefault();
  };
  return (
    <Form className={'formElement'} onSubmit={preventSubmit}>
      <SearchIcon />
      <Input
        className={'searchInput ' + searchCustom}
        type="text"
        placeholder="Pesquisar um termo"
        aria-label="Pesquisar um termo"
        onChange={(e) => refine(e.target.value)}
        {...rest}
      />
    </Form>
  );
});
