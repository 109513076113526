import React, { useEffect } from 'react';
import clsx from 'clsx';

import ArrowSvg from '../images/opened';
import config from '../../../config';
import Link from '../link';

const TreeNode = ({
  className = '',
  toggle,
  collapsed,
  collapsedList,
  url,
  title,
  items,
  firstLevel,
  ...rest
}) => {
  const hasChildren = items.length !== 0;
  const isCollapsed = collapsedList[url] !== undefined ? collapsedList[url] : true;

  const collapse = () => {
    toggle(url);
  };

  let location;

  if (typeof document != 'undefined') {
    location = document.location;
  }

  const active =
    location &&
    (location.pathname.replace(/\/$/, '') === url ||
      location.pathname.replace(/\/$/, '') === config.gatsby.pathPrefix + url);

  const calculatedClassName = clsx(className, 'item', {
    active,
  });

  useEffect(() => {
    if (active) {
      const paths = url.replace(/^\//, '').split('/');

      paths
        .slice(0, paths.length - 1)
        .forEach((path, i) => {
          const parents = paths.slice(0, i);
          toggle(`${parents.length > 0 ? `/${parents.join('/')}` : ''}/${path}`, false);
        });
    }
  }, [active])

  return (
    <li className={calculatedClassName}>
      {!config.sidebar.frontLine && title && hasChildren && (
        <button onClick={collapse} className="collapser">
          {!isCollapsed ? (
            <ArrowSvg style={{ transform: 'rotate(180deg)' }} />
          ) : (
            <ArrowSvg />
          )}
        </button>
      )}

      {title && <Link to={url}>{title}</Link>}

      {hasChildren && (
        <ul>
          {items.map((item) => (
            <TreeNode
              key={item.url}
              collapsedList={collapsedList}
              className={!firstLevel && clsx({ hide: isCollapsed })}
              toggle={toggle}
              {...item}
            />
          ))}
        </ul>
      )}
    </li>
  );
};

TreeNode.defaultProps = {
  collapsed: true,
};

export default TreeNode;
