import { css } from '@emotion/core';

export const arrowBtn = css`
  cursor: pointer;
  -moz-box-align: center;
  -moz-box-direction: normal;
  -moz-box-orient: horizontal;
  margin: 0px;
  padding: 0px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  place-self: stretch;
  text-decoration: none;
`;

export const arrowIcon = css`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  margin: 0px;
  color: rgb(157, 170, 182);
  flex: 0 0 auto;
  font-size: 24px;
  transition: color 200ms ease 0s;
  padding: 3px;
  border-radius: 50%;
  background-color: #9013FE;
  color: white;
`;
