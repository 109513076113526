import React, { useState } from 'react';
import { StaticQuery, graphql, navigate } from 'gatsby';
import styled from '@emotion/styled';
import { Sun as DisabledSun } from 'styled-icons/boxicons-regular/Sun';
import { Sun } from 'styled-icons/boxicons-solid/Sun';
import { Moon } from 'styled-icons/boxicons-solid/Moon';
import { Moon as DisabledMoon } from 'styled-icons/boxicons-regular/Moon';
import { Popover } from 'reactstrap';
import compose from 'lodash/fp/compose';

import { mq } from './theme';
import config from '../../config';
import './styles.css';
import EyeSwitch from './EyeSwitch';
import { withThemeChanger } from './themeChangerProvider';
import { withFirebase } from './Firebase/context';

const isSearchEnabled =
  config.header.search && config.header.search.enabled ? true : false;

let searchIndices = [];
if (isSearchEnabled && config.header.search.indexName) {
  searchIndices.push({
    name: `${config.header.search.indexName}`,
    title: `Results`,
    hitComp: `PageHit`,
  });
}

const Nav = styled.nav(
  mq({
    minHeight: 76,
    borderBottom: '1px solid #737689',
    marginLeft: [10, 10, 36],
    display: 'flex',
    alignItems: 'center',
  }),
);

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.font};
  margin: 0;
  text-transform: uppercase;
  font-size: 30px;
  font-family: Avenir, Arial, Helvetica, sans-serif;
  font-weight: 300;
  a,
  a:focus,
  a:hover {
    color: inherit;
    text-decoration: none;
  }
`;

const Header = ({ firebase, mdx, currentTheme, changeTheme }) => (
  <StaticQuery
    query={graphql`
      query headerTitleQuery {
        site {
          siteMetadata {
            headerTitle
            githubUrl
            helpUrl
            tweetText
            logo {
              link
              image
            }
            headerLinks {
              link
              text
            }
          }
        }
      }
    `}
    render={(data) => {
      const {
        site: {
          siteMetadata: { headerTitle, githubUrl, helpUrl, logo, headerLinks },
        },
      } = data;

      const [popOver, setPopOver] = useState(false);
      const togglePopOver = (value) =>
        setPopOver(typeof value === 'boolean' ? value : !popOver);

      const contentPopover = () => {
        return (
          <div
            className="menu-list"
            style={{
              padding: '10px 20px',
            }}
          >
            <button
              type="button"
              className="menu-item"
              style={{
                backgroundColor: 'transparent',
                border: 'none',
                color: 'inherit',
                padding: 0,
              }}
              onClick={async () => {
                await firebase.doSignOut();
                navigate('/login');
              }}
            >
              Sair
            </button>
          </div>
        );
      };

      return (
        <div className={'navBarWrapper'}>
          <Nav>
            <div className={'navbar-header navBarHeader'}>
              <Title>{mdx.fields.title}</Title>
            </div>
            <div style={{ marginLeft: 'auto' }}>
              <EyeSwitch
                checked={currentTheme === 'light'}
                postIcon={currentTheme === 'light' ? Sun : DisabledSun}
                preIcon={currentTheme === 'light' ? DisabledMoon : Moon}
                onChange={() =>
                  changeTheme(currentTheme === 'light' ? 'dark' : 'light')
                }
              />
            </div>
            <div style={{ flexShrink: 0, marginLeft: 16 }}>
              <button
                type="button"
                id="UserPopover"
                style={{
                  border: 'none',
                  display: 'block',
                  borderRadius: '50%',
                  backgroundColor: '#737689',
                  width: 32,
                  height: 32,
                  cursor: 'pointer',
                }}
                onClick={togglePopOver}
              />
              <Popover
                placement="top"
                isOpen={popOver}
                toggle={togglePopOver}
                target="UserPopover"
              >
                {contentPopover()}
              </Popover>
            </div>
          </Nav>
        </div>
      );
    }}
  />
);

const enhance = compose(withThemeChanger, withFirebase);

export default enhance(Header);
