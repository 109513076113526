import facepaint from 'facepaint';

export const mq = facepaint([
  '@media screen and (min-width: 767px)',
  '@media screen and (min-width: 1023px)',
]);

export default {
  fonts: {
    mono: '"SF Mono", "Roboto Mono", Menlo, monospace',
  },
};

const lightTheme = {
  colors: {
    background: '#7D1DD1',
    foreground: '#FFFFFF',
    font: '#737689',
    contentMenu: '#F5F7FA',
    evenTableRow: 'rgba(0, 0, 0, 0.05)',
  },
};

const darkTheme = {
  colors: {
    background: '#7D1DD1',
    foreground: '#222222',
    font: '#FFFFFF',
    contentMenu: '#464646',
    evenTableRow: 'rgba(255, 255, 255, 0.15)',
  },
};

export const complimentaryThemes = {
  light: lightTheme,
  dark: darkTheme,
};
