import React from 'react';
import styled from '@emotion/styled';
import { MDXProvider } from '@mdx-js/react';
import { css } from '@emotion/core';
import compose from 'lodash/fp/compose';

import { mq } from './theme';
import Header from './Header';
import ThemeProvider from './themeProvider';
import mdxComponents from './mdxComponents';
import Sidebar from './sidebar';
import RightSidebar from './rightSidebar';
import ThemeChangerProvider from './themeChangerProvider';
import withAuthorization from './Session/withAuthorization';
import withAuthentication from './Session/withAuthentication';
import 'bootstrap/dist/css/bootstrap.min.css';

const Wrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.foreground};
  transition: background-color 0.2s;

  @media only screen and (max-width: 767px) {
    display: block;
  }
`;

const Content = styled('main')`
  display: flex;
  flex-grow: 1;
  margin: 0px 88px 0 36px;

  ul {
    margin-left: 17px;
  }

  @media only screen and (max-width: 1023px) {
    padding-left: 0;
    margin: 0 10px;
    margin-top: 3rem;
  }
`;

const MaxWidth = styled('div')`
  flex-grow: 1;

  @media only screen and (max-width: 50rem) {
    width: 100%;
    position: relative;
  }
`;

const LeftSideBarWidth = styled('div')((props) =>
  mq({
    width: 388,
    flexShrink: 0,
    display: ['none', 'none', 'block'],
    backgroundColor: props.theme.colors.background,
    zIndex: 100,
    position: 'relative',
  }),
);

const RightSideBarWidth = styled('div')(
  mq({
    width: 290,
    flexShrink: 0,
    display: ['none', 'none', 'block'],
  }),
);

const content = css(
  mq({
    width: '100%',
    paddingRight: [10, 32],
  }),
);

const Layout = ({ children, location, data }) => {
  return (
    <ThemeChangerProvider>
      <ThemeProvider location={location}>
        <MDXProvider components={mdxComponents}>
          <Wrapper>
            <LeftSideBarWidth>
              <Sidebar location={location} />
            </LeftSideBarWidth>
            <div css={content}>
              <Header mdx={data.mdx} location={location} />
              <div style={{ display: 'flex', paddingTop: 24 }}>
                <Content>
                  <MaxWidth>{children}</MaxWidth>
                </Content>
                <RightSideBarWidth>
                  <RightSidebar location={location} />
                </RightSideBarWidth>
              </div>
            </div>
          </Wrapper>
        </MDXProvider>
      </ThemeProvider>
    </ThemeChangerProvider>
  );
};

const enhance = compose(
  withAuthentication,
  withAuthorization((authUser) => !!authUser),
);

export default enhance(Layout);
