import React, { useState } from 'react';
import { ThemeProvider as EmotionThemeProvider } from 'emotion-theming';

import './styles.css';
import { default as defaultTheme, complimentaryThemes } from './theme';
import { withThemeChanger } from './themeChangerProvider';

function ThemeProvider({ children, currentTheme }) {
  return (
    <EmotionThemeProvider
      theme={{ ...defaultTheme, ...complimentaryThemes[currentTheme] }}
    >
      {children}
    </EmotionThemeProvider>
  );
}

export default withThemeChanger(ThemeProvider);
