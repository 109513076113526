import React, { useState, useContext } from 'react';

import './styles.css';

export const ThemeContext = React.createContext({
  currentTheme: null,
  changeTheme: () => {},
});

export const withThemeChanger = (WrappedComponent) => (props) => {
  const contextValues = useContext(ThemeContext);
  return <WrappedComponent {...props} {...contextValues} />;
};

export default function ThemeChangerProvider({ children }) {
  const storedTheme = localStorage.getItem('KB_currentTheme');
  const [currentTheme, setCurrentTheme] = useState(storedTheme || 'light');

  const handleChangeTheme = (value) => {
    localStorage.setItem('KB_currentTheme', value);
    setCurrentTheme(value);
  };

  return (
    <ThemeContext.Provider
      value={{ currentTheme, changeTheme: handleChangeTheme }}
    >
      {children}
    </ThemeContext.Provider>
  );
}
