import * as React from 'react';
import * as PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { useTheme } from 'emotion-theming';

const eyeSwitch = css`
  position: relative;
  display: inline-block;
  width: 48px;
  height: 25px;
  margin: 0;

  & input {
    opacity: 0;
    width: 0;
    height: 0;
  }
`;

const eyeSlider = css`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d5d5d5;
  transition: background-color 0.4s;

  &:before {
    position: absolute;
    content: '';
    height: 19px;
    width: 19px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: background-color 0.4s, transform 0.2s;
  }

  input:checked + & {
    background-color: #07dcac;
  }

  input:focus + & {
    box-shadow: 0 0 1px #07dcac;
  }

  input:checked + &:before {
    transform: translateX(22px);
  }

  /* Rounded sliders */
  &.round-slider {
    border-radius: 32px;
  }

  &.round-slider:before {
    border-radius: 50%;
  }
`;

function EyeSwitch({
  t,
  checked,
  onChange,
  label,
  containerClassName,
  labelClassName,
  preIcon: PreIcon,
  postIcon: PostIcon,
}) {
  const theme = useTheme();

  return (
    <div style={{ display: 'flex' }} css={containerClassName}>
      {
        <PreIcon
          style={{
            flexShrink: 0,
            width: 24,
            marginRight: 8,
            color: theme.colors.font,
          }}
        />
      }
      <div>
        <label css={eyeSwitch}>
          <input type="checkbox" checked={checked} onChange={onChange} />
          <span className="round-slider" css={eyeSlider} />
        </label>
      </div>
      {
        <PostIcon
          style={{
            flexShrink: 0,
            width: 24,
            marginLeft: 8,
            color: theme.colors.font,
          }}
        />
      }
      {label && (
        <div style={{ display: 'flex', width: '100%' }}>
          <label css={labelClassName}>{`${t(label)} `}</label>
        </div>
      )}
    </div>
  );
}

EyeSwitch.defaultProps = {
  label: '',
  checked: false,
  onChange: () => {},
  labelClassName: '',
  containerClassName: '',
  preIcon: undefined,
  postIcon: undefined,
};

EyeSwitch.propTypes = {
  checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  onChange: PropTypes.func,
  label: PropTypes.string,
  containerClassName: PropTypes.string,
  labelClassName: PropTypes.string,
};

export default EyeSwitch;
